require.config({
  wrap: true,
  paths: {
    breakpoint: 'lib/jquery.breakpoints',
    owl: 'lib/owl.carousel.min',
    owlSlide: 'lib/owl.slide',
  }
});

define('modernizr', [], window.Modernizr);

requirejs([
  'jquery',
  'modernizr',
  'breakpoint',
  'owl',
  'owlSlide'
  ],
  function($, modernizr) {
    $(function() {


      // ==============================================
      //  CONTENT SLIDER
      // ==============================================
      var $sliderImages = $(".content-slider .item");
      var counter = 0;
      
      function checkInit () {
        counter++;

        if (counter === $sliderImages.length) {

          $(".content-slider").owlSlide({
            slideSpeed : 300,
            paginationSpeed : 300,
            rewindSpeed : 300,
            navigationText : false,
            navigation:true
          });
        }    
      }

      $sliderImages.each(function() {
        var $item = $(this);
        var $img = $item.find("img");
        var imgWidth = $img.attr("width");

        if (imgWidth > 0) {
          $item.css({'width':imgWidth+'px'});
          checkInit();
        } else {
          $img[0].onload = function(){
            imgWidth = $img.attr("width");            
            $item.css({'width':imgWidth+'px'});

            checkInit();
          };
        }
      });  
      


      // ==============================================
      //  NAV MAIN TOGGLE
      // ==============================================
      $('#menutoggle').on('click', function(ev) {
        ev.preventDefault();
        $(this).siblings(".nav-main").stop(true, true).slideToggle(150);
      });



      // ==============================================
      //  KEYVISUAL SLIDER
      // ==============================================
      var initSlider = function() {
        var owl = $("#keyvisual-slider");
        owl.owlCarousel({
          navigation : true,
          slideSpeed : 400,
          paginationSpeed : 400,
          rewindSpeed : 400,
          singleItem:true,
          navigationText : false,
          lazyLoad : true
        });
      };



      // ==============================================
      //  NO SVG SUPPORT
      // ==============================================
      if(!modernizr.svg) {
        $('img[src$="svg"]').attr('src', function() {
          return $(this).attr('src').replace('.svg', '.png');
        });
      }



      // ==============================================
      //  CONVERT MAIL ADRESS
      // ==============================================
      require(['convertMailAddress'], function (convertMailAddress) {
        convertMailAddress();
      });



      // ==============================================
      // BREAKPOINTS
      // ==============================================
      var onDesktopNormal = function () {
        $(".nav-main").removeAttr("style");
        initSlider();
      };

      var mobileHorz = function () {

      };

      var mobileVert = function () {

      };

      $(window).breakpoints({
        callback: function(device) {
          switch(device) {
            case "desktop-normal":
              onDesktopNormal();
              break;
            case "mobile-horz":
              mobileHorz();
              break;
            case "mobile-vert":
              mobileVert();
              break;
            default:
              break;
          }
        },
        eventNamespace: 'rm',
        helperElementId: 'rm-helper'
      });


    });
  }
);

define("main", function(){});

